@import 'variables';

.badge {
  display: inline-block;
  font-size: 0.75em;
  padding: 0.625rem;
  line-height: 1;
  border-radius: 0.5rem;
  font-weight: bold;
  white-space: nowrap;
  background-color: #edecff;
  color: #8683ff;

  &--large {
    padding: 0.75rem 1.25rem;
  }

  &--secondary {
    background-color: transparentize(#3df9c6, 0.89);
    color: #1ad9a5;
  }
}
