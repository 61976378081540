.aos-init {
  opacity: 0; // Init animation
}

.fade-in {
  opacity: 1 !important;
  will-change: opacity;
  animation: 0.8s 0.3s fadeIn ease-out backwards;
}

.fade-right {
  opacity: 1 !important;
  transform: translateX(0);
  animation: 0.8s fadeRight cubic-bezier(0.7, 0, 0.3, 1);
}

.fade-right--big {
  opacity: 1 !important;
  will-change: opacity, transform;
  transform: translateX(0);
  animation: 0.8s fadeRightBig cubic-bezier(0.7, 0, 0.3, 1);
}

.fade-left {
  opacity: 1 !important;
  transform: translateX(0);
  animation: 0.8s fadeLeft cubic-bezier(0.7, 0, 0.3, 1);
}

.fade-left--slow {
  transform: translateX(0);
  animation: 1s fadeLeftSlow cubic-bezier(0.7, 0, 0.3, 1);
}

.fade-bottom {
  opacity: 1 !important;
  transform: translateY(0);
  animation: 0.8s fadeBottom cubic-bezier(0.7, 0, 0.3, 1);
}

.pop-in {
  opacity: 1 !important;
  transform: scale(1);
  animation: 0.8s popIn cubic-bezier(0.7, 0, 0.3, 1);
}

.pop-in--light {
  opacity: 1 !important;
  transform: scale(1);
  animation: 0.8s popInLight cubic-bezier(0.7, 0, 0.3, 1);
}

@keyframes popIn {
  0% {
    transform: scale(0.3);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes popInLight {
  0% {
    transform: scale(0.95);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeBottom {
  0% {
    transform: translateY(5%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeLeft {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeLeftSlow {
  0% {
    transform: translateX(5%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadeRightBig {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeRight {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
