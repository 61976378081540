@import 'variables';

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 8.5rem;
  padding: 0.875rem 1.25rem;
  background-color: $theme-btn-bg;
  border: none;
  border-radius: 0.625rem;
  color: $theme-btn-text;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  outline-offset: 1px;
  outline-color: $theme-btn-bg;
  transition: color 0.15s ease-out, background-color 0.15s ease-out, opacity 0.15s ease-out;
  cursor: pointer;
  position: relative;
  opacity: 1;

  &__icon {
    margin-left: 0.625rem;
    transition: transform 0.15s ease-out;
  }

  &__icon--left {
    margin-right: 0.625rem;
    transition: transform 0.15s ease-out;
  }

  &--loading:disabled {
    opacity: 1;
    color: white;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparentize($wizbii-green, 0.5);
    transform: scaleX(0.8);
    transform-origin: 0;
    animation: loaderAnim 1s ease-out;
    z-index: 10;
  }

  @keyframes loaderAnim {
    0% {
      transform: scaleX(0.1);
    }
    30% {
      transform: scaleX(0.3);
    }
    80% {
      transform: scaleX(0.8);
    }
  }

  &:hover {
    color: white;
    text-decoration: none;

    & > .btn__icon {
      transform: scale(1.1) translateX(0.125rem);
    }
  }

  &:disabled, // use attribute `disabled` for buttons
  &--disabled {
    // use class `btn--disabled` with `tabindex="-1"` for links
    opacity: 0.3;
    pointer-events: none;
  }

  &--link.btn {
    // increased specificity required due to theming
    min-width: 0;
    background-color: transparent;
    color: inherit;
    outline-color: currentColor;
    font-weight: 500;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }

  &--ghost.btn {
    // increased specificity required due to theming
    color: inherit;
    background-color: transparent;
    border: 1px solid $theme-btn-bg;
    transition: background-color 150ms ease-out;

    &:hover {
      color: inherit;
      background-color: $theme-btn-bg;
    }
  }

  &--shadow {
    box-shadow: 0 0.125rem 0.25rem 0 rgba(134, 130, 255, 0.23);
    transition: color 0.15s ease-out, background-color 0.15s ease-out, opacity 0.15s ease-out, box-shadow 150ms ease-out,
      transform 150ms ease-out, color 150ms ease-out;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 0.1275rem 0.255rem 0 rgba(134, 130, 255, 0.23);
    }

    &:active {
      transform: scale(0.97);
      box-shadow: 0 0.12125rem 0.2425rem 0 rgba(134, 130, 255, 0.23);
    }
  }
}

.btn-disc {
  display: inline-flex;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  color: inherit;
  outline-offset: 1px;
  cursor: pointer;

  &__text {
    margin-right: 0.75rem;
    font-size: 0.9375rem;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__icon {
    flex: 0 0 auto;
    display: flex !important; // override `app-svg-icon` host binding
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    background-color: $theme-btn-bg;
    border-radius: 50%;
    color: $theme-btn-text;
    transition: transform 100ms ease-out, color 100ms ease-out;
  }

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &:hover &__icon {
    transform: scale(1.1);
  }

  @media (min-width: $bp-lg) {
    &__icon {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

.btn-clean {
  display: inline-block;
  padding: 0;
  background: transparent;
  border: none;
  color: inherit;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
