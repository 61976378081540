@import 'variables';

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
.mat-tab-nav-bar {
  .mat-tab-links {
    .mat-tab-link {
      padding: 0;
      margin: 0 0.9375rem;
      font-size: 0.875rem;
      font-weight: normal;
      height: 2.75rem;
      min-width: 5.625rem;
      opacity: 1;
      cursor: pointer;
      color: transparentize(white, 0.2);

      &.mat-tab-label-active {
        color: white;
        font-weight: bold;
      }
    }

    mat-ink-bar {
      background-color: $wizbii-black;
    }
  }
}
