@import 'variables';

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type
mat-form-field.mat-form-field-appearance-outline {
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border-width: 0.062rem !important;
  }

  .mat-form-field-outline-start {
    min-width: 0.625rem;
    border-radius: 0.625rem 0 0 0.625rem;
  }

  .mat-form-field-outline-end {
    min-width: 0.625rem;
    border-radius: 0 0.625rem 0.625rem 0;
  }

  .mat-form-field-suffix {
    top: -0.25rem;
  }

  .mat-form-field-infix {
    padding: 0.85em 0 1.15em 0;
  }

  .mat-form-field-wrapper {
    margin: 0;
  }

  .mat-form-field-subscript-wrapper {
    font-weight: 500;
    margin-top: 0.25rem;
    padding: 0;
    text-align: right;

    mat-error {
      font-size: 0.625rem;
    }
  }

  &:not(.mat-form-field-invalid):not(.mat-focused).mat-form-field-has-label.mat-form-field-should-float {
    .mat-form-field-label {
      color: $wizbii-black;
    }
  }

  &.mat-form-field-invalid {
    mat-datepicker-toggle {
      color: $invalid-red;
    }
  }

  mat-select {
    .mat-select-arrow {
      content: '';
      transition: transform 0.15s;
      border: none;
      width: 1rem;
      height: 1rem;
      mask: url('~/assets/icons/chevron-down.svg') no-repeat center;
      background-color: currentColor;
    }

    &[aria-owns] {
      .mat-select-arrow {
        transform: rotate(-180deg);
      }
    }
  }
}
